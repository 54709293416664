<div class="orderheader">
  <h2 style="font-size: 22px">訂單內容</h2>
</div>
<!-- <h2 class="order-title">您的訂單紀錄</h2> -->
<section class="order-content">
  <div
    class="process-step"
    [ngClass]="{ 'process-step--cancel': detailInfo.itemStatus == 4 }"
  >
    <div
      class="process-step__item process-step__item--completed"
      [ngClass]="{
        'process-step__item--completed-last':
          detailInfo.itemStatus == 0 || detailInfo.itemStatus == 4,
        'process-step__item--completed-cancel': detailInfo.itemStatus == 4
      }"
    >
      <span class="process-step__text-wrap">
        <span class="process-step__title">收到訂單</span>
        <!-- if has date data -->
        <span class="process-step__date" *ngIf="detailInfo.orderDT">{{
          detailInfo.orderDT | date : "yyyy/MM/dd"
        }}</span>
      </span>
    </div>
    <!-- <div class="process-step__item" *ngIf="detailInfo.itemStatus!=4"
      [ngClass]="{'process-step__item--completed':detailInfo.itemStatus>=1,'process-step__item--completed-last':detailInfo.itemStatus==1}">
      <span class="process-step__text-wrap">
        <span class="process-step__title">訂單處理中</span>
      </span>
    </div> -->
    <div>
      <section class="process-step">
        <div
          class="process-step__item process-step__item--completed process-step__item--completed-last"
        >
          <span class="process-step__text"> 1 確認訂單 </span>
          <div class="line"></div>
        </div>
        <div
          class="process-step__item-gray process-step__item--completed process-step__item--completed-last"
        >
          <span class="process-step__text-gray">
            2 {{ detailInfo.orderStatusName }}
          </span>
        </div>
      </section>
    </div>
    <!-- <div
      class="process-step__item process-step__item--last process-step__item--completed process-step__item--completed-last"
      *ngIf="detailInfo.itemStatus==4">
      <span class="process-step__text-wrap">
        <span class="process-step__title">訂單取消</span>
      </span>
    </div> -->
  </div>
  <!--訂單檢視/我的票夾-->
  <div class="main-function"></div>
  <div class="list-section-wrap">
    <div class="list-section">
      <h3 class="list-section__title">訂單明細</h3>
      <ul class="order-detail-list">
        <li class="order-detail-list__item order-detail-list__item--first-row">
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
          ></span>
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
            >商品資訊/商品類型/訂單編號</span
          >
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
            >兌換點數/金額</span
          >
          <!-- <span class="order-detail-list__cell order-detail-list__cell--first-row">功能</span> -->
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
            >付款狀態</span
          >
          <!-- <span class="order-detail-list__cell order-detail-list__cell--first-row">點數狀態</span> -->
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
            >訂單狀態</span
          >
          <span
            class="order-detail-list__cell order-detail-list__cell--first-row"
            >票券序號</span
          >
        </li>
        <li class="order-detail-list__item">
          <!-- nth-child(1) only show in mobile width -->
          <span
            class="order-detail-list__cell"
            [attr.data-category]="'訂單編號:' + detailInfo.orderNo"
          >
          </span>
          <span class="order-detail-list__cell">
            <img
              class="order-detail-list__image"
              src="{{ detailInfo.details[0].img }}"
              alt=""
            />
            <span class="order-detail-list__info">
              <span class="order-detail-list__info-text">{{
                detailInfo.details[0].productType
              }}</span>
              <span class="order-detail-list__info-text">{{
                detailInfo.details[0].name
              }}</span>
              <span class="order-detail-list__info-text">{{
                detailInfo.details[0].orderNo
              }}</span>
              <span class="order-detail-list__info-text__expiration"
                >商品兌換期</span
              >
              <span class="order-detail-list__info-text__expiration">{{
                detailInfo.details[0].expiration
              }}</span>
            </span>
          </span>
          <span class="order-detail-list__cell">
            <div
              class="order-detail-list__transaction-info"
              style="width: 30%; max-width: 90px"
            >
              <div>件數</div>
              <div>{{ detailInfo.details.length }}</div>
            </div>
            <div
              class="order-detail-list__transaction-info"
              style="width: 70%; border-left: 1px #f0f0f0 solid"
            >
              <div>訂購總額</div>
              <div *ngIf="detailInfo.allVBonus > 0">
                <img
                  class="order-detail-list__point"
                  src="/assets/images/icashpay-icon/points.svg"
                  alt=""
                />
                {{ detailInfo.allVBonus | number }}
              </div>
              <div *ngIf="detailInfo.allPrice > 0">
                <img
                  class="order-detail-list__point"
                  src="/assets/images/icashpay-icon/points.svg"
                  alt=""
                />
                {{ detailInfo.allPrice | number }}
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="info-section-wrap">
    <div
      class="order-info-section"
      [ngClass]="{ 'order-info-section--full': !detailInfo.isHasDelivery }"
    >
      <h3 class="order-info-section__title">訂單資訊</h3>
      <div style="background-color: #ffffff; border-radius: 8px; padding: 8px">
        <div class="order-info-section__info-wrap">
          <h6 class="order-info-section__sub-title">訂購日期</h6>
          <p class="order-info-section__text">
            {{ detailInfo.orderDT | date : "yyyy/MM/dd HH:mm" }}
          </p>
        </div>
        <!-- <div class="order-info-section__info-wrap" *ngIf="detailInfo.details[0].payType==2">
          <h6 class="order-info-section__sub-title">發票類型</h6>
          <p class="order-info-section__text">{{detailInfo.invoiceType}}</p>
        </div> -->
        <!-- <div class="order-info-section__receipt-note-wrap" *ngIf="detailInfo.details[0]?.payType==2&&detailInfo.invoiceType=='電子信箱'">
          <p class="order-info-section__receipt-note">
            <span class="note__icon-wrap">
              <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
            </span>
            <a target="_blank" href="https://giftshop.systex.com/"
              class="order-info-section__receipt-link">您可透過電子郵件中的帳號密碼查詢您的發票資訊。</a>
          </p>
        </div> -->
        <div class="order-info-section__info-wrap">
          <h6 class="order-info-section__sub-title">票券效期</h6>
          <p class="order-info-section__text">
            {{ detailInfo.details[0].useLimitDate | date : "yyyy/MM/dd HH:mm" }}
          </p>
        </div>
        <div style="position: relative">
          <div
            class="order-info-section__info-wrap"
            *ngFor="let item of detailInfo.details; let i = index"
          >
            <!-- <h6 class="order-info-section__sub-title">票券序號</h6> -->
            <!-- <div style="height: auto;min-width: 100px;" *ngIf="i!==0 && i<5"></div> -->
            <!-- <p class="order-info-section__text">{{item.sn}} {{ item.orderStatusName }}</p> -->
            <!-- <div style="height: auto;min-width: 100px;" *ngIf="TicktOpen ==true && i>=1"></div>
            <p class="order-info-section__text" *ngIf="TicktOpen ==true && i>=1">{{item.sn}} {{ item.orderStatusName }}
            </p> -->
            <ng-container *ngIf="!TicktOpen && i <= 1">
              <h6 class="order-info-section__sub-title">票券序號</h6>
              <p class="order-info-section__text">
                {{ item.sn }} {{ item.orderStatusName }}
              </p>
            </ng-container>

            <ng-container *ngIf="TicktOpen">
              <h6 class="order-info-section__sub-title">票券序號</h6>
              <p class="order-info-section__text">
                {{ item.sn }} {{ item.orderStatusName }}
              </p>
            </ng-container>
          </div>
          <div style="height: 20px"></div>
          <div
            class="bring-Information"
            *ngIf="!TicktOpen && detailInfo.details.length > 1"
          >
            <a (click)="TicktOpen = true">顯示更多</a>
          </div>
          <!-- <div class="order-info-section__info-wrap" *ngIf="TicktOpen==true;">
            <div style="height: auto;min-width: 100px;"></div><a class="bring-Information"
              (click)="TicktOpen=false;">收合</a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="delivery-info-section" *ngIf="detailInfo.isHasDelivery">
      <h3 class="delivery-info-section__title">宅配資訊</h3>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">收件者姓名</h6>
        <p class="delivery-info-section__text">{{detailInfo.recipent}}</p>
      </div>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">收件者電話</h6>
        <p class="delivery-info-section__text">{{detailInfo.tel}}</p>
      </div>
      <div class="delivery-info-section__info-wrap">
        <h6 class="delivery-info-section__sub-title">宅配地址</h6>
        <p class="delivery-info-section__text">{{detailInfo.address}}</p>
      </div>
      <div class="delivery-info-section__info-wrap" *ngIf="detailInfo.deliveryNo">
        <h6 class="delivery-info-section__sub-title delivery-info-section__sub-title--last">貨運單號</h6>
        <p class="delivery-info-section__text delivery-info-section__text--last">
          {{detailInfo.deliveryNo}}
          <a href="{{detailInfo.deliveryLink}}" target="_blank">(連結)</a>
        </p>
      </div>
    </div> -->
  </div>
</section>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <button
      [routerLink]="['/']"
      class="process-step-button__button process-step-button__button--cancel"
    >
      回首頁
    </button>
    <button
      [routerLink]="['/member/ticket']"
      class="process-step-button__button process-step-button__button--next"
    >
      查看票券
    </button>
  </div>
</div>
