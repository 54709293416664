<!-- <h2 class="cart-title">購物車</h2> -->
<section class="cart-content" *ngIf="cartNumber > 0">
  <section class="process-step">
    <div
      class="process-step__item process-step__item--completed process-step__item--completed-last"
    >
      <span class="process-step__text"> 1 確認訂單 </span>
    </div>
    <div
      class="process-step__item-gray process-step__item--completed process-step__item--completed-last"
    >
      <span class="process-step__text-gray"> 2 交易成功 </span>
    </div>
  </section>

  <ul class="product-list">
    <div class="itemback">
      <h2 class="cart-title">商品資訊</h2>
      <li
        class="product-list__item"
        *ngFor="let item of infos; let isLast = last"
        [ngClass]="{ 'product-list__item--last-row': isLast }"
      >
        <span class="product-list__cell">
          <img class="product-list__image" src="{{ item.img }}" alt="" />
          <span class="product-list__info">
            <span class="product-list__info-text">{{ item.productType }}</span>

            <span
              class="product-list__info-text"
              [ngClass]="{
                'product-list__info-text--mb5mobile': item.limitPointType
              }"
              >{{ item.productName }}
              <!-- red label -->
              <span
                *ngIf="false"
                class="product-list__activity-label-wrap product-list__activity-label-wrap--first"
                ><img
                  class="product-list__activity-label"
                  src="/assets/images/icon/product_red_label.svg"
                  alt=""
                />
              </span>
              <!-- green label -->
              <span *ngIf="false" class="product-list__activity-label-wrap"
                ><img
                  class="product-list__activity-label"
                  src="/assets/images/icon/product_green_label.svg"
                  alt=""
                />
              </span>
              <!-- reach label-->
              <span
                *ngIf="false"
                class="product-list__activity-label-wrap product-list__activity-label-wrap--last"
                ><img
                  class="product-list__activity-label"
                  src="/assets/images/icon/product_reach_label.svg"
                  alt=""
                />
              </span>
            </span>
            <span class="product-list__info-text">{{ item.sn }}</span>
            <span
              class="point-dollar-number point-dollar-number--bold cartprice"

            >
              <img *ngIf="checkoutReq.PaymentMethod == 1"
                class="point-dollar-number__image"
                style="
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                  margin-bottom: 0;
                "
                src="/assets/images/icashpay-icon/points.svg"
                alt=""
              />
              <img *ngIf="checkoutReq.PaymentMethod == 2"
                class="point-dollar-number__image"
                style="
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                  margin-bottom: 0;
                "
                src="/assets/images/icashpay-icon/money.svg"
                alt=""
              />
              <span class="point-dollar-number__text"
                >{{
                  checkoutReq.PaymentMethod == 1
                    ? (exchangeType.point | number)
                    : (exchangeType.price | number)
                }}
                <!-- {{ checkoutReq.PaymentMethod == 1 ? "點" : "元" }} -->
                </span
              >
            </span>
            <!-- <div class="input-group">
              <label class="input-group__label" for="Number_Products">
                商品數量
              </label>
              <input class="purchinput" id="Number_Products" type="number" placeholder="請輸入商品數量"
                [(ngModel)]="item.amount" (keyup)="amountChange($event,item)">


            </div> -->
            <!-- <span *ngIf="item.limitPointType" class="product-list__info-text product-list__info-text--limit-point-type">
              <span><img src="/assets/images/icon/shock_mark_white.svg" alt=""></span>
              <span>限定 使用{{item.limitPointType}}</span>
            </span> -->
            <!-- <span class="product-list__info-text product-list__info-text--mobile-amount-button-wrap">
              <button class="product-list__amount-button" (click)="calc(item,'-')">-</button>
              <button class="product-list__amount-button">{{item.amount}}</button>
              <button class="product-list__amount-button" (click)="calc(item,'+')">+</button>
            </span> -->
          </span>
        </span>
        <!-- 商品不足時顯示 -->
        <span
          class="product-list__empty-mask"
          *ngIf="item.remainingProductNum <= 0"
        >
          <button
            class="product-list__empty-button"
            (click)="initDeleteModal(item, true)"
          >
            商品已銷售完畢，請點選移除商品
          </button>
          <p class="product-list__empty-note">
            <span class="note__icon-wrap">
              <img
                class="note__icon"
                src="/assets/images/icon/shock_mark_white.svg"
                alt=""
              />
            </span>
            <span>提醒您，移除後商品會加入您的收藏清單中</span>
          </p>
        </span>
      </li>

      <div class="cart-title detail">兌換方式</div>
      <select
        class="input-group__select input-group__select--receipt-info"
        name="receipt-category"
        id=""
        [(ngModel)]="checkoutReq.PaymentMethod"
        (change)="exchangeTypeChange()"
      >
        <ng-container *ngFor="let item of exchangeTypeList">
          <option selected [ngValue]="item.type">
            {{
              item.type == 1 ? "openpoint" : item.type == 2 ? "icashpay" : ""
            }}
            {{item.count == 0 ? "(無庫存)":"" }}
          </option>
        </ng-container>
      </select>

      <div class="cart-title detail">訂單聯絡信箱</div>
      <input class="search" type="search" placeholder="請填寫聯絡信箱" [(ngModel)]="checkoutReq.Email">

      <div *ngIf="checkoutReq.PaymentMethod ==2">
        <!--*ngIf="!(receiptType==4 && !hasCharityTypeProduct)" -->
        <div class="cart-title detail">發票資訊</div>
        <div class="buyer-receipt-info__text-wrap">
          <!-- <p class="buyer-receipt-info__text">
            1.因開立發票需有您的個人資料，請您務必輸入正確購買者資訊。(如您同意預存發票資訊，日後購買商品時將自動帶出。)<br>
            2.若發票中獎，發票類型選擇電子信箱之用戶，紙本發票將會以掛號寄送到您提供的資料並以e-mail通知您。</p> -->
          <!-- <div class="checkcenter">
          <div  class="anonymous-checkbox-wrap">
              <input id="anonymous-cBox" class="signature-modal__input" type="checkbox">
             <p>紀錄上一次資料</p>
            </div>
            </div> -->

          <!-- <p class="buyer-receipt-info__text">
            紀錄上一次資料</p>
            <a class="privacy-link" href="#" (click)="handlePrivacyLinkClick($event)">我同意悠客券的網站 服務條款 及 隱私權政策</a> -->
        </div>
        <div class="input-group" style="margin-bottom: 0px;">
          <select (change)="resetReceiptInfoInput()" class="input-group__select input-group__select--receipt-info"
            name="receipt-category" id="" [(ngModel)]="receiptType" (ngModelChange)="changeInvoiceType($event)">
            <option selected value=1>電子信箱</option>
            <option value=2>手機條碼</option>
            <option value=3>自然人憑證</option>
            <option value=4>發票捐贈</option>
            <!-- <option value=5>三聯式發票</option> -->
          </select>
        </div>
        <!-- ngIf -->
        <div class="receipt-info__text-wrap" *ngIf="receiptType==1">
          <p class="receipt-info__text">
            訂單發票資訊將會寄送至您填寫的信箱
          </p>
        </div>
        <div class="receipt-info__text-wrap" *ngIf="receiptType==2">
          <input style="margin-top: 4px;" (input)="onInputChange()" [(ngModel)]="receiptInfoInput2"
            class="receipt-info__input" type="text" placeholder="/1234ABC">
          <span class="receipt-info__warning"
            *ngIf="!isReceiptInfoInputValid(receiptInfoInput2) && isReceiptInfoInput2Changed && receiptInfoInput2.trim().length>0">請輸入正確格式</span>
          <p class="receipt-info__text">
            請先至財政部電子發票整合服務平台申請手機條碼，請輸入條碼下方「符號及大寫英文及數字」共8碼。(範例：/1234ABC)
          </p>
        </div>
        <div class="receipt-info__text-wrap" *ngIf="receiptType==3">
          <input style="margin-top: 4px;" (input)="isReceiptInfoInput3Changed=true" [(ngModel)]="receiptInfoInput3"
            class="receipt-info__input" type="text" placeholder="AB123 456 7890 1234">
          <span class="receipt-info__warning"
            *ngIf="!isReceiptInfoInputValid(receiptInfoInput3) && isReceiptInfoInput3Changed && receiptInfoInput3.trim().length>0">請輸入正確格式</span>
          <p class="receipt-info__text">
            請先至有辦理自然人憑證業務之戶政事務所申請，請輸入憑證條碼下方「大寫英文數字」。(範例：AB123 456 7890 1234)
          </p>
        </div>
        <!-- <div class="receipt-info__text-wrap" *ngIf="receiptType == 5">

          <input [(ngModel)]="receiptInfoInput5Name"
            class="receipt-info__input" type="text" placeholder="請輸入公司名稱" [maxlength]="42">
        </div>
        <div class="receipt-info__text-wrap" *ngIf="receiptType == 5">
          <input [(ngModel)]="receiptInfoInput5Number"
            class="receipt-info__input" type="text" placeholder="請輸入統一編號" (change)="checkCompanyNumber()" [maxlength]="8">
          <span class="receipt-info__warning" *ngIf="!isCompanyNumberPass && receiptInfoInput5Number"
            >請輸入正確統編格式</span>
        </div> -->
        <div class="receipt-info__text-wrap" style="margin-top: 8px;" *ngIf="receiptType==4">
          <div class="contact-form__radio-wrap">
            <input type="radio" name="donate" class="contact-form__radio" [(ngModel)]="Donate" [value]="true">
            <label for="" class="contact-form__radio-label">兒童福利聯盟文教基金會</label>
          </div>
          <p class="receipt-info__text">
            如您於訂購時選擇將發票捐贈，依據法令規定，已捐贈的發票無法索取紙本發票及更改捐贈對象，如有退換貨需求，精誠資訊股份有限公司將會將該發票作廢。
          </p>
        </div>
        <!-- ngIf -->

        <div>
          <div class="cart-title detail">購買者發票/收據資訊</div>
          <div style="margin-bottom: 12px;">若發票中獎，該紙本發票將以掛號寄送到購買者之地址，並以E-mail通知購買者；如您是選擇公益商品，將會開立收據至您填寫的資訊中，故請務必輸入正確購買者資訊。</div>
          <div class="input-group" style="margin-bottom: 10px;">
            <label class="input-group__label" for="buyer-receipt-name">
              姓名
            </label>
            <input [(ngModel)]="checkoutReq.InvoiceName" type="text" class="input-group__input"
              id="buyer-receipt-name">
          </div>
          <div class="input-group" style="margin-bottom: 10px;">
            <label class="input-group__label" for="buyer-receipt-phone">
              聯絡電話
            </label>
            <input [(ngModel)]="checkoutReq.InvoicePhone" maxlength="10" (keypress)="phoneCheck($event)" type="text"
              class="input-group__input" id="buyer-receipt-phone">
          </div>
          <div class="input-group input-group--address-city" style="margin-bottom: 12px;">
            <label class="input-group__label" for="buyer-receipt-address">
              地址
            </label>
            <div>
              <select [(ngModel)]="checkoutReq.InvoiceCity" #receiptAddressCity
                class="input-group__select input-group__select--address-city" name="buyer-receipt-address-city"
                (change)="zipcodeFilter(receiptAddressCity.value, 'receipt')">
                <option disabled selected="selected" value="">城市/縣</option>
                <option [value]="item.id" *ngFor="let item of counties">{{item.id}}</option>
              </select>
              <select [(ngModel)]="checkoutReq.InvoiceArea"
                class="input-group__select input-group__select--address-city" name="buyer-receipt-address-town">
                <option disabled selected="selected" value="">地區/鄉鎮</option>
                <option [value]="item.city+'|'+item.zipcode" *ngFor="let item of selectedZipcodeForReceiptAddress">
                  {{item.city}}
                </option>
              </select>
            </div>
          </div>
          <div class="input-group input-group--address-street">
            <input [(ngModel)]="checkoutReq.InvoiceAddress"
              class="input-group__input input-group__input--address-street" id="buyer-receipt-address" type="text"
              placeholder="請輸入詳細地址">
          </div>
          <!-- <div style="color:#E7007F">＊請填寫正確的發票資訊，以免無法將中獎發票寄出。</div>
          <div class="buyer-receipt-info__recent-address-wrap">
            <div *ngIf="hasCharityTypeProduct" class="anonymous-checkbox-wrap">
              <input id="anonymous-cBox" class="anonymous-checkbox-wrap__input" type="checkbox"
                (click)="IsCharityAnonymousCheckboxChecked=!IsCharityAnonymousCheckboxChecked">
              <label for="anonymous-cBox" class="anonymous-checkbox-wrap__label"
                [ngClass]="{'anonymous-checkbox-wrap__label--active': IsCharityAnonymousCheckboxChecked}">不同意捐款姓名公開揭露
              </label>
            </div>
          </div> -->
        </div>

        <!-- <div class="checkandbring">
          <div>
            <div class="checkcenter">
              <input id="signature-checkbox01" class="signature-modal__checkbox" type="checkbox"
                [(ngModel)]="checkoutReq.IsAgreeInvoiceInfo" />
              <label for="signature-checkbox01" class="signature-modal__label" [ngClass]="{
            'signature-modal__label--active':checkoutReq.IsAgreeInvoiceInfo
          }"> <a class="privacy-black" >我同意預存發票資訊</a></label>
            </div>
            <div class="checkcenter">
              <input id="signature-checkbox02" class="signature-modal__checkbox" type="checkbox"
                [(ngModel)]="isReadpersonalCheckbox" />
              <label for="signature-checkbox02" class="signature-modal__label" [ngClass]="{
            'signature-modal__label--active':isReadpersonalCheckbox
          }"> <a class="privacy-black" href="#" (click)="handlePrivacyLinkClick($event)">我同意<p class="privacy-link">悠客券
                  服務條款及隱私權政策</p></a></label>
            </div>
            <div class="checkcenter">
              <input id="signature-checkbox03" class="signature-modal__checkbox" type="checkbox"
                [(ngModel)]="isReturnCheckbox" />
              <label for="signature-checkbox03" class="signature-modal__label" [ngClass]="{
            'signature-modal__label--active':isReturnCheckbox
          }"> <a class="privacy-black" >我同意訂單銷貨辦理退貨時，由開立發票單位（精誠資訊股份有限公司）代為處理發票及銷貨退回證明。</a></label>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </ul>

  <section class="cart-form">
    <div class="note__warning-list">
      <ng-container *ngFor="let item of infos">
        <div
          *ngIf="item.ticketPrecautions"
          [innerHTML]="item.ticketPrecautions | html"
        ></div>
      </ng-container>
    </div>

    <div class="process-step-button row">
      <div class="process-step-button__button-wrap">
        <div class="order-top-info-cell">
          <!-- <span class="order-top-info__cell">訂單金額</span> -->
          <span class="point-dollar-number purchPrice">
            <img *ngIf="checkoutReq.PaymentMethod == 1"
              class="point-dollar-number__image"
              style="width: 24px; height: 24px"
              src="/assets/images/icashpay-icon/points.svg"
              alt=""
            />
            <img *ngIf="checkoutReq.PaymentMethod == 2"
              class="point-dollar-number__image"
              style="width: 24px; height: 24px"
              src="/assets/images/icashpay-icon/money.svg"
              alt=""
            />
            <span class="point-dollar-number__text"
              >{{
                checkoutReq.PaymentMethod == 1
                  ? (totalPoint | number)
                  : (totalPrice | number)
              }}
              <!-- {{ checkoutReq.PaymentMethod == 1 ? "點" : "元" }} -->
              </span
            >
          </span>
        </div>
        <ng-container *ngFor="let item of infos">
          <div>
            <span class="text">數量</span>
            <select
              class="input-group__select input-group__select--receipt-info"
              name="receipt-category"
              id=""
              style="width: 80px"
              [(ngModel)]="item.amount"
              (change)="amountChange($event, item)"
            >
              <option
                selected
                value="{{ item }}"
                *ngFor="let item of numbertem"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </ng-container>
      </div>
      <div class="process-step-button__button-wrap">
        <a
          class="process-step-button__button process-step-button__button--cancel"
          (click)="goback()"
          >回上一頁</a
        >
        <button
          class="process-step-button__button process-step-button__button--next"
          style="height: 45px"
          (click)="sendValidate()"
        >
          <div>立刻兌換</div>
          <!-- <img src="/assets/images/icashpay-icon/shopping-cart.svg" alt=""> -->
        </button>
      </div>
    </div>
  </section>
</section>
<!-- alertModal -->
<app-modal
  [isModalOpen]="isAlertModalOpen"
  (modalClose)="isAlertModalOpen = $event"
>
  <h4 headerContent class="general-modal__title">提示訊息</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text" *ngFor="let row of alertMsg.split('br')">
      {{ row }}
    </p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isAlertModalOpen = false"
    >
      確認
    </button>
  </div>
</app-modal>
<!-- confirmModal -->
<app-modal
  [isModalOpen]="isComfirmModalOpen"
  (modalClose)="isComfirmModalOpen = $event"
>
  <h4 headerContent class="general-modal__title">提示訊息</h4>
  <div bodyContent class="general-modal__text-wrap">
    <ng-container *ngIf="confirmModalStep == 1">
      <p
        class="general-modal__text"
        *ngFor="let word of preConfirmMessage.split('br/')"
      >
        {{ word }}
      </p>
    </ng-container>
    <ng-container *ngIf="confirmModalStep == 2">
      <p
        class="general-modal__text"
        *ngFor="let word of confirmMessege.split('br/')"
      >
        {{ word }}
      </p>
    </ng-container>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button
      class="general-modal__button general-modal__button--cancel"
      (click)="isComfirmModalOpen = false"
    >
      取消
    </button>
    <button
      *ngIf="confirmModalStep == 1"
      class="general-modal__button general-modal__button--confirm"
      (click)="confirmModalStep = 2"
    >
      確認
    </button>
    <button
      *ngIf="confirmModalStep == 2"
      class="general-modal__button general-modal__button--confirm"
      (click)="checkout()"
    >
      確認
    </button>
  </div>
</app-modal>
<!-- deleteModal -->
<app-modal
  [isModalOpen]="isDeleteModalOpen"
  (modalClose)="isDeleteModalOpen = $event"
>
  <h4 headerContent class="general-modal__title">刪除商品</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">您確定要移此此項商品?</p>
    <p class="general-modal__text">{{ deleteModalData.productName }}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button
      class="general-modal__button general-modal__button--cancel"
      (click)="isDeleteModalOpen = false"
    >
      取消
    </button>
    <button
      class="general-modal__button general-modal__button--confirm"
      (click)="deleteCart(deleteModalData)"
    >
      確認
    </button>
  </div>
</app-modal>

<div class="cart-content cart-content--empty" *ngIf="cartNumber == 0">
  <div class="cart-empty__wrap">
    <img
      class="cart-empty__image"
      src="/assets/images/icon/cart_empty.svg"
      alt=""
    />
    <p class="cart-empty__text">您的購物車是空的</p>
    <a class="cart-empty__button" [routerLink]="['/']">去購物吧</a>
  </div>
</div>

<!-- privacy modal -->

<app-modal
  [isModalOpen]="isPointModalOpen"
  (modalClose)="isPointModalOpen = $event"
>
  <h3 headerContent class="general-modal__title__privacy">
    <img
      style="width: 56px; height: 56px"
      src="/assets/images/icashpay-icon/dialog.png"
      alt=""
    />
  </h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <div>點數不足</div>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button
      class="general-modal__button general-modal__button--confirm"
      (click)="isPointModalOpen = false"
    >
      確認
    </button>
  </div>
</app-modal>
