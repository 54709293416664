<div class="orderheader">
  <h2 class="order-title">訂單查詢</h2>
</div>
<section class="order-content" *ngIf="listType == 1">
  <!-- <section class="search-condition">
    <div class="search-condition__wrap search-condition__wrap--time">
      <span>顯示最近</span>
      <select class="search-condition__select search-condition__select--time" [(ngModel)]="ListReq.TimeRange">
        <option value="3" selected>3個月</option>
        <option value="6">6個月</option>
        <option value="12">12個月</option>
        <option value="24">24個月</option>
      </select> 交易紀錄
    </div>
    <div class="search-condition__wrap search-condition__wrap--amount search-condition__wrap--button">
      <span>顯示</span>
      <select class="search-condition__select search-condition__select--amount" [(ngModel)]="tempPageCount">
        <option value="10" selected>10筆</option>
        <option value="30">30筆</option>
      </select>
      <button class="search-condition__button" (click)="getOrderList(true)">查詢</button>
    </div>
  </section> -->
  <section>
    <!-- 29056 -->
    <div style="color: #a8a8a8">＊僅呈現近12個月訂單</div>
    <ul #orderList class="main-list">
      <!-- <li class="main-list__item main-list__item--first-row">
        <span class="main-list__cell main-list__cell--first-row">訂單編號</span>
        <span class="main-list__cell main-list__cell--first-row">交易日期</span>
        <span class="main-list__cell main-list__cell--first-row">商品內容</span>
        <span class="main-list__cell main-list__cell--first-row">件數</span>
        <span class="main-list__cell main-list__cell--first-row">總金額</span>
        <span class="main-list__cell main-list__cell--first-row">訂單狀態</span>
        <span class="main-list__cell main-list__cell--first-row">功能</span>
      </li> -->
      <!-- <li class="main-list__item" *ngFor="let item of ListRes; trackBy:trackByIdentify">
        <span class="main-list__cell" data-category="訂單編號">
          <span>
            {{item.orderMainNo}}
          </span>
        </span>
        <span class="main-list__cell">
          <span>
            {{item.transDT}}
          </span>
        </span>
        <span class="main-list__cell" data-attr="商品內容">
          <span>
            <span class="product-type">{{item.prodType}}</span>
            <span class="product-name">{{item.prodName}}</span>
          </span>
        </span>
        <span class="main-list__cell" data-attr="件數">
          <span>
            {{item.prodCount}}
          </span>
        </span>
        <span class="main-list__cell" data-attr="點數">
          <span class="point-dollar-number">
            <img class="point-dollar-number__image" src="/assets/images/icon/money-red.svg" alt="">
            <span class="point-dollar-number__text">{{item.points}}點</span>
          </span>
        </span>
        <span class="main-list__cell" data-attr="訂單狀態">
          <span>
            {{item.orderStatusName}}
          </span>
        </span>
        <span class="main-list__cell">
          <span>
            <a class="main-list__detail-link" [routerLink]="['/member','order', 'detail', item.orderMainNo]">查看訂單內容</a>
          </span>
        </span>
      </li> -->
    </ul>
    <div class="main-list">
      <ng-container *ngIf="ListRes && ListRes.length > 0">
        <div class="main-list__ord" *ngFor="let item of ListRes">
          <div class="row" style="margin-left: 0px">
            <div class="main-list__no">
              <div>訂單編號:{{ item.orderMainNo }}</div>
            </div>
          </div>
          <div>
          <div class="row main-list__name" style="margin-left: 0px">
            <div>{{ item.prodType }} {{ item.prodName }}</div>
            <!-- <div></div> -->
          </div>
        </div>
          <div class="row" style="margin-left: 0px">
            <div class="col" style="padding-left: 0px">
              <div>件數</div>
              <div>{{ item.prodCount }}</div>
            </div>
            <div class="col" style="border-left: 1px #f0f0f0 solid">
              <div>訂購總額</div>
              <div *ngIf="item.points > 0">
                <img
                  class="main-list__point"
                  src="/assets/images/icashpay-icon/points.svg"
                  alt=""
                />
                {{ item.points | number }}
              </div>
              <div *ngIf="item.prices > 0">
                <img
                  class="main-list__point"
                  src="/assets/images/icashpay-icon/points.svg"
                  alt=""
                />
                {{ item.prices | number }}
              </div>
            </div>
            <div class="col" style="border-left: 1px #f0f0f0 solid">
              <div>訂單狀態</div>
              <div>{{ item.orderStatusName }}</div>
            </div>
          </div>
          <a
            class="main-list__detail-link"
            [routerLink]="['/member', 'order', 'detail', item.orderMainNo]"
            >查看訂單內容</a
          >
        </div>
      </ng-container>

      <ng-container *ngIf="ListRes && ListRes.length <= 0">
        <div class="main-list__noData">
          <div class="main-list__noData content">
            <div>
              <img
                src="/assets/images/icashpay-icon/icashpay-icon.svg"
                alt=""
              />
            </div>
            <div>目前未有訂單資訊</div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
  <app-pagination
    *ngIf="totalDataCount > ListReq.PageCount"
    [currentPage]="this.ListReq.PageNum"
    [anchorElement]="orderList"
    [totalItems]="totalDataCount"
    [itemsPerPageData]="perPageCountModel"
    (SelectPage)="goToPage($event)"
  >
  </app-pagination>
</section>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <a
      class="process-step-button__button process-step-button__button--cancel"
      (click)="goback()"
      >回上一頁</a
    >
    <div style="min-width: 20px"></div>
    <button
      class="process-step-button__button process-step-button__button--next"
      (click)="goTicket()"
    >
      開啟我的票夾
    </button>
  </div>
</div>

<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen = $event">
  <h3 headerContent class="general-modal__title">取消訂單申請</h3>
  <!-- 取消訂單 -->
  <div
    bodyContent
    *ngIf="!modalStepCompleted && !modalReturnLastStep"
    class="general-modal__body"
  >
    <div>
      <p class="cancel-order-modal__text cancel-order-modal__text--mb5">
        提醒您：
      </p>
      <p class="cancel-order-modal__text">
        當供應商已發送「已出貨通知」，您將無法再提出取消交易請求，確認所要取消或辦理退貨的商品後，
      </p>
      <p class="cancel-order-modal__text">
        按下「訂單取消」，將立即為您辦理相關事宜，如有相關問題可洽「聯絡客服」功能，由客服人員為您服務。
      </p>
      <p class="cancel-order-modal__text cancel-order-modal__text--mb10">
        請輸入取消訂單原因(如選擇其他請填寫說明)：
      </p>
    </div>
    <div class="cancel-order-modal__reason-section">
      <div class="cancel-order-modal__radio-wrap">
        <input
          name="cancel-order-reason"
          class="cancel-order-modal__radio"
          type="radio"
          value="重複購買"
          [(ngModel)]="cancelOrderReq.Remark"
        />
        <label class="cancel-order-modal__label" for="">重複購買</label>
      </div>
      <div class="cancel-order-modal__radio-wrap">
        <input
          name="cancel-order-reason"
          class="cancel-order-modal__radio"
          type="radio"
          value="出貨太慢"
          [(ngModel)]="cancelOrderReq.Remark"
        />
        <label class="cancel-order-modal__label" for="">出貨太慢</label>
      </div>
      <div class="cancel-order-modal__radio-wrap">
        <input
          name="cancel-order-reason"
          class="cancel-order-modal__radio"
          type="radio"
          value="other"
          [(ngModel)]="cancelOrderReq.Remark"
        />
        <label class="cancel-order-modal__label" for="">其他(請輸入說明)</label>
      </div>
    </div>
    <div class="">
      <textarea
        class="cancel-order-modal__textarea"
        name="cancel-order-textarea"
        id=""
        rows="5"
        [(ngModel)]="otherRemark"
      ></textarea>
    </div>
  </div>
  <div
    bodyContent
    *ngIf="modalStepCompleted || modalReturnLastStep"
    class="general-modal__body"
    [ngClass]="{
      'general-modal__body--completed':
        modalStepCompleted || modalReturnLastStep
    }"
  >
    <p class="general-modal__text">{{ modalMessage }}</p>
  </div>
  <!--  -->
  <div
    footerContent
    *ngIf="!modalStepCompleted && !modalReturnLastStep"
    class="general-modal__button-wrap"
  >
    <button
      class="general-modal__button general-modal__button--cancel"
      (click)="isModalOpen = false"
    >
      取消
    </button>
    <button
      class="general-modal__button general-modal__button--confirm"
      (click)="cancelOrder()"
    >
      取消訂單
    </button>
  </div>
  <div
    footerContent
    *ngIf="modalReturnLastStep"
    class="general-modal__button-wrap"
  >
    <button
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="modalStepCompleted = false; modalReturnLastStep = false"
    >
      返回
    </button>
  </div>
  <div
    footerContent
    *ngIf="modalStepCompleted"
    class="general-modal__button-wrap"
  >
    <button
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isModalOpen = false"
    >
      確認
    </button>
  </div>
</app-modal>

<!--  -->

<app-modal
  [isModalOpen]="isReturnProductModalOpen"
  (modalClose)="isReturnProductModalOpen = $event"
>
  <h3 headerContent class="general-modal__title">退貨申請</h3>
  <!-- 申請退貨 -->
  <div
    bodyContent
    *ngIf="!returnProductModalStepCompleted"
    class="general-modal__body"
  >
    <div>
      <p class="return-product-modal__text return-product-modal__text--mb5">
        提醒您：
      </p>
      <p class="return-product-modal__text">
        7天鑑賞期指的是猶豫期而非試用期，您僅可檢視商品外觀但不能使用，若是經過使用發現新品不良或商品本身有問題則不在此限，您仍可提出退貨申請。
      </p>
      <p class="return-product-modal__text">
        申請退貨時，請務必將您所訂購之完整商品、配件、贈品及包裝盒置入於原外包裝箱內，並將商品回復原狀，並向取貨人員收取退貨簽單並保留該單據到收到退款為止，以利後續退換貨作業。
      </p>
      <p class="return-product-modal__text">
        7天鑑賞期的算法是從收到商品的隔天開始算7個日曆天（包含例假日）。
      </p>
      <p class="return-product-modal__text return-product-modal__text--mb10">
        請選擇退貨原因 (如選擇其他 請填寫說明)：
      </p>
    </div>
    <div class="return-product-modal__reason-section">
      <div class="return-product-modal__radio-wrap">
        <input
          name="return-product-reason"
          class="return-product-modal__radio"
          type="radio"
          value="商品缺件"
          [(ngModel)]="returnProductReq.Remark"
        />
        <label class="return-product-modal__label" for="">商品缺件</label>
      </div>
      <div class="return-product-modal__radio-wrap">
        <input
          name="return-product-reason"
          class="return-product-modal__radio"
          type="radio"
          value="商品部分功能不能使用"
          [(ngModel)]="returnProductReq.Remark"
        />
        <label class="return-product-modal__label" for=""
          >商品部分功能不能使用</label
        >
      </div>
      <div class="return-product-modal__radio-wrap">
        <input
          name="return-product-reason"
          class="return-product-modal__radio"
          type="radio"
          value="我收到不對的商品(賣家寄錯商品)"
          [(ngModel)]="returnProductReq.Remark"
        />
        <label class="return-product-modal__label" for=""
          >我收到不對的商品(賣家寄錯商品)</label
        >
      </div>
      <div class="return-product-modal__radio-wrap">
        <input
          name="return-product-reason"
          class="return-product-modal__radio"
          type="radio"
          value="other"
          [(ngModel)]="returnProductReq.Remark"
        />
        <label class="return-product-modal__label" for=""
          >其他(請輸入說明)</label
        >
      </div>
    </div>
    <div class="">
      <textarea
        class="return-product-modal__textarea"
        name="return-product-textarea"
        id=""
        rows="5"
        [(ngModel)]="returnProductOtherRemark"
      ></textarea>
    </div>
    <div class="return-product-modal__note-wrap">
      <p class="return-product-modal__note">
        <span class="note__icon-wrap">
          <img
            class="note__icon"
            src="/assets/images/icon/shock_mark_white.svg"
            alt=""
          />
        </span>
        <span
          >退貨申請後，需要7~14的工作天，如有疑問可洽聯絡我們聯繫客服人員。</span
        >
      </p>
    </div>
  </div>
  <div
    bodyContent
    *ngIf="returnProductModalStepCompleted"
    class="general-modal__body"
    [ngClass]="{
      'general-modal__body--completed': returnProductModalStepCompleted
    }"
  >
    <p class="general-modal__text">{{ returnProductModalMessage }}</p>
  </div>
  <!--  -->
  <div
    footerContent
    *ngIf="!returnProductModalStepCompleted"
    class="general-modal__button-wrap"
  >
    <button
      class="general-modal__button general-modal__button--cancel"
      (click)="isReturnProductModalOpen = false"
    >
      取消
    </button>
    <button
      class="general-modal__button general-modal__button--confirm"
      (click)="returnProduct()"
    >
      申請退貨
    </button>
  </div>
  <div
    footerContent
    *ngIf="returnProductModalStepCompleted"
    class="general-modal__button-wrap"
  >
    <button
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isReturnProductModalOpen = false"
    >
      確認
    </button>
  </div>
</app-modal>
