import { LoaderService } from './../../../shared/service/loader.service';
import { ItemsPerPageData } from './../../../shared/model/pagination';
import { LoginService } from './../../../shared/service/login.service';
import { MbrInfo, OrderListReq, CancelOrderReq, OrderMainListRes, OrderItemListRes, ReturnProductReq } from './../../../shared/model/member';
import { HttpService } from './../../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/shared/service/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-member-order',
  templateUrl: './member-order.component.html',
  styleUrls: ['./member-order.component.scss']
})
export class MemberOrderComponent implements OnInit {
  isModalOpen = false;
  modalReturnLastStep = false;
  modalStepCompleted = false;
  modalMessage: string;
  ListReq: OrderListReq = new OrderListReq();
  ListRes: OrderMainListRes[] = [];
  totalDataCount: number = 10;
  userInfo: MbrInfo;

  //品項列表
  paramsOrderMainNo = '';
  ItemListOrderMainNo = '';
  ItemListRes: OrderItemListRes[] = [];

  cancelOrderReq = new CancelOrderReq();
  otherRemark: string;
  tempPageCount: number = 10;
  perPageCountModel: ItemsPerPageData = new ItemsPerPageData();

  listType: number = 1;
  hasCanReturn = false;
  //退貨
  returnProductReq = new ReturnProductReq();
  returnProductOtherRemark: string;
  isReturnProductModalOpen = false;
  returnProductModalStepCompleted = false;
  returnProductModalMessage: string;

  constructor(private httpService: HttpService,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.headerService.Ticket = true;
    this.headerService.Global = true;
    this.headerService.Footer = false;
    this.headerService.Order = false;
    this.route.queryParams.subscribe(params => {
      if (params.orderMainNo) {
        // this.getItemList(params.orderMainNo);
        this.paramsOrderMainNo = params.orderMainNo;
      }
    })

    this.userInfo = this.loginService.GetLocalInfo();
    this.ListReq.MbrID = this.userInfo.ID;
    this.ListReq.MbrToken = this.userInfo.Token;
    this.ListReq.TimeRange = 12; // 固定 12 個月

    this.getOrderList();
    // }
    // this.callPagetagService();
  }

  ngOnDestroy(): void {
    this.headerService.Ticket = false;
    this.headerService.Order = true;
    this.headerService.Global = true;
    this.headerService.Footer = true;
  }

  trackByIdentify(index: number, item: OrderMainListRes) {
    return item.orderMainNo;
  }

  openCancelOrderModal(itemNo: string) {
    this.modalStepCompleted = false;
    this.modalReturnLastStep = false;
    this.cancelOrderReq.OrderNumber = itemNo;
    this.otherRemark = '';
    this.cancelOrderReq.Remark = '';
    this.isModalOpen = true;
  }
  cancelOrder() {
    if (!this.cancelOrderReq.Remark) {
      this.modalReturnLastStep = true;
      this.modalMessage = "請選擇取消訂單原因";
      return;
    }
    if (this.cancelOrderReq.Remark == "other") {
      if (this.otherRemark) {
        this.cancelOrderReq.Remark = this.otherRemark;
      } else {
        this.modalReturnLastStep = true;
        this.modalMessage = "請輸入其他原因";
        return;
      }
    }
    this.isModalOpen = false;
    this.loaderService.start();
    this.httpService.post("/Member/CancelOrder", this.cancelOrderReq).subscribe(
      (x: any) => {
        this.isModalOpen = true;
        this.modalStepCompleted = true;
        this.modalMessage = x.result;
        this.getOrderList();
        this.loaderService.stop();
      }
    )
  }
  getOrderList(isReset?: boolean) {
    this.changePageCount(isReset);

    // 重新搜尋
    if (isReset) { this.ListReq.PageNum = 1; };


    this.httpService.post("/Member/GetOrderMainList", this.ListReq).subscribe(
      (x: any) => {
        this.ListRes = x.orderRows;
        this.totalDataCount = x.allDataCount;
      }
    )

  }
  goTicket() {
    this.router.navigate(['/member/ticket']);
  }

  goback() {
    this.location.back();
  }

  // getItemList(orderMainNo: string) {
  //   this.loaderService.start();
  //   this.httpService.get("/Member/GetOrderItemList?orderMainNo=" + orderMainNo).subscribe(
  //     (x: any) => {
  //       this.ItemListOrderMainNo = orderMainNo;
  //       this.ItemListRes = x.itemRows;
  //       let _hasCanReturn = false;
  //       this.ItemListRes.forEach(item => {
  //         if (item.canReturn) {
  //           _hasCanReturn = true;
  //         }
  //       })
  //       this.hasCanReturn = _hasCanReturn;

  //       this.listType = 2;

  //       this.loaderService.stop();
  //     }
  //   )
  // }

  goToPage(page: number) {
    this.ListReq.PageNum = page;
    this.getOrderList();
  }

  changePageCount(isReset?: boolean) {
    if (isReset) {
      this.ListReq.PageCount = +this.tempPageCount;
      var count: number = +this.tempPageCount;

    } else {
      var count: number = +this.ListReq.PageCount;
    }

    this.perPageCountModel.min = count;
    this.perPageCountModel.sm = count;
    this.perPageCountModel.md = count;
    this.perPageCountModel.lg = count;
    this.perPageCountModel.xl = count;

    if (isReset) {
      // 重新賦址，觸發分頁元件ngOnChange()
      this.perPageCountModel = { ...this.perPageCountModel }
    }
  }

  callPagetagService(url = '', orderID = '') {
    if (url.length == 0 || orderID.length == 0) {
      this.pagetagService.callPageTag(this.router.url, '會員服務 | 訂單查詢')
      return;
    }
    this.pagetagService.callPageTag(url, `會員服務 | 訂單查詢 | ${orderID}`)
  }

  backToMainOrderList(event: any) {
    if (event) {
      event.preventDefault();
    }
    this.listType = 1;
    this.callPagetagService();
    if (this.paramsOrderMainNo.length == 0) { return; }

    this.router.navigate(['/member', 'order']);
  }

  openReturnProductModal(itemNo: string) {
    this.returnProductModalStepCompleted = false;
    this.returnProductReq.SN = itemNo;
    this.returnProductReq.ItemNo = itemNo;
    this.returnProductOtherRemark = '';
    this.returnProductReq.Remark = '';
    this.isReturnProductModalOpen = true;
  }

  returnProduct() {
    if (!this.returnProductReq.Remark) {
      this.returnProductModalStepCompleted = true;
      this.returnProductModalMessage = "請選擇申請退貨原因";
      return;
    }
    if (this.returnProductReq.Remark == "other") {
      if (this.returnProductOtherRemark) {
        this.returnProductReq.Remark = this.returnProductOtherRemark;
      } else {
        this.returnProductModalStepCompleted = true;
        this.returnProductModalMessage = "請輸入其他原因";
        return;
      }
    }
    this.isReturnProductModalOpen = false;
    this.loaderService.start();
    this.httpService.post("/Member/ReturnOrder", this.returnProductReq).subscribe(
      (x: any) => {
        this.returnProductModalStepCompleted = true;
        this.isReturnProductModalOpen = true;
        this.returnProductModalMessage = x.result;
        // this.getItemList(this.ItemListOrderMainNo);
        this.loaderService.stop();
      }
    )
  }
}
